/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'
import { TrainerAvailability } from '@/api/interfaces/trainerAvailability'

export interface TrainerAvailabilityState {
  items: Array<TrainerAvailability>
  isLoading: boolean
}

@Module({ name: 'trainerAvailability', namespaced: true })
class TrainerAvailabilityModule extends VuexModule implements TrainerAvailabilityState {
  public items: Array<TrainerAvailability> = []
  public isLoading = false

  @Mutation
  public setItems (items: Array<TrainerAvailability>): void {
    this.items = items
  }

  @Mutation
  public setIsLoading (isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Action({ rawError: true })
  public async load (params: {
    queryParams: QueryParams,
    idLogisticSession: number,
  }): Promise<Array<TrainerAvailability>> {
    try {
      this.context.commit('setIsLoading', true)
      const option = constructUrlQueryString(params.queryParams)
      const response = await http.get(`/api/trainers_availability/logistic_sessions/${params.idLogisticSession}${option}`)
      this.context.commit('setItems', response.data['hydra:member'])
      return response.data['hydra:member'] as Array<TrainerAvailability>
    } finally {
      this.context.commit('setIsLoading', false)
    }
  }
}

export default TrainerAvailabilityModule
