import { format } from 'date-fns'
import type { ItemWithPosition } from '@/views/dashboard/planning/types/Calendar'
import type Filters from '@/views/dashboard/planning/types/Filters'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { TrainingSessionStatus } from '@/api/enums/trainingSessionStatus'

class IsInTheFilters {
  filters: Partial<Filters>
  items: ItemWithPosition[] | undefined
  userConnectedIsTrainerRegionManager: boolean
  constructor () {
    this.filters = {}
    this.items = []
    this.userConnectedIsTrainerRegionManager = false
  }

  private matchTrainingType (item: ItemWithPosition) {
    if (
      this.filters.trainingType && this.filters.trainingType.length > 0 &&
      !item.logisticSession.trainingSessions.some(
        (ts) => this.filters?.trainingType?.includes((ts.trainingType as TrainingType)['@id'] || ''),
      )
    ) {
      return false
    }
    return true
  }

  private matchTrainer (item: ItemWithPosition) {
    if (
      this.filters.trainer && this.filters.trainer.length > 0 &&
      !item.logisticSession.trainers.some(
        (itemTrainer) => this.filters?.trainer?.some((trainer) => trainer['@id'] === itemTrainer['@id']),
      )
    ) {
      return false
    }
    return true
  }

  private matchStatus (item: ItemWithPosition) {
    if (
      this.filters.status && this.filters.status.length > 0 &&
      !item.logisticSession.trainingSessions.some(
        (ts) => ts.status && this.filters?.status?.includes(ts.status),
      )
    ) {
      return false
    }
    return true
  }

  private matchTechnicalPreparation (item: ItemWithPosition) {
    if (
      this.filters.technicalPreparation !== null &&
      item.logisticSession.technicalPreparation !== this.filters.technicalPreparation
    ) {
      return false
    }
    return true
  }

  private matchTrainingLocation (item: ItemWithPosition) {
    if (
      this.filters.trainingLocation && this.filters.trainingLocation.length > 0 &&
      !this.filters?.trainingLocation?.some((location) => location['@id'] === item.logisticSession.trainingLocation['@id'])
    ) {
      return false
    }
    return true
  }

  private matchArea (item: ItemWithPosition) {
    if (
      this.filters.area && this.filters.area.length > 0 &&
      !item.logisticSession.trainingSessions.some(
        (ts) => this.filters?.area?.includes(ts.trainingLocation.area),
      )
    ) {
      return false
    }
    return true
  }

  private matchCompany (item: ItemWithPosition) {
    if (
      this.filters.company && this.filters.company.length > 0 &&
      !item.logisticSession.trainingSessions.some(
        (session) => {
          return session.needTrainings.some((trainee) => this.filters.company?.some((company) => trainee.company['@id'] === company['@id']))
        })
    ) {
      return false
    }

    return true
  }

  private matchDisplayCanceled (item: ItemWithPosition) {
    if (!this.filters.displayCanceled) {
      return !this.hasAllSessionCanceled(item)
    }
    return true
  }

  public hasAllSessionCanceled (item: ItemWithPosition) {
    return item.logisticSession.trainingSessions.every((session) => session.status === TrainingSessionStatus.STATUS_CANCELED)
  }

  private checkAllCondition (item: ItemWithPosition) {
    return this.matchTrainingType(item) && this.matchTrainer(item) && this.matchStatus(item) && this.matchTechnicalPreparation(item) && this.matchTrainingLocation(item) && this.matchCompany(item) && this.matchArea(item)
  }

  set newFilters (data: { filters: Filters, items: ItemWithPosition[] }) {
    this.filters = data.filters
  }

  set newItems (data: { filters: Filters, items: ItemWithPosition[] }) {
    this.items = data.items
  }

  public updateValue (filters: Filters, items: ItemWithPosition[], userConnectedIsTrainerRegionManager: boolean) {
    this.filters = filters
    this.items = items
    this.userConnectedIsTrainerRegionManager = userConnectedIsTrainerRegionManager
  }

  get dtoFiltered (): ItemWithPosition[] {
    if (this.items?.length) {
      const dayMaxPosition = this.items.reduce((acc: Map<string, number>, item) => {
        const date: string = this.getDateFormated(item.logisticSession.startAt)
        if (!acc.has(date) || (acc.get(date) as number < item.y && !this.hasAllSessionCanceled(item))) {
          acc.set(date, item.y)
        }
        return acc
      }, new Map<string, number>())
      return this.items.map((item) => {
        item.isDisabled = !this.checkAllCondition(item)
        item.isDisplayed = this.matchDisplayCanceled(item)
        if (this.hasAllSessionCanceled(item)) {
           item.isCanceled = true
           const date = this.getDateFormated(item.logisticSession.startAt)
          if (dayMaxPosition.has(date)) {
            const dayMaxPositionValue = dayMaxPosition.get(date) as number
            // trick : + 1 to display these sessions after the last one
            item.y = dayMaxPositionValue > 0 ? dayMaxPositionValue : dayMaxPositionValue + 1
          } else {
            item.y = 0
          }
        }
        return item
      })
    }
    return []
  }

  private getDateFormated (dateISO: string | Date | undefined): string {
    if (!dateISO) {
      throw Error('Date indéfinie')
    }

    if (dateISO instanceof Date) {
      return format(dateISO, 'yyyy-MM-dd')
    }

    const date = new Date(dateISO)
    return format(date, 'yyyy-MM-dd')
  }
}

export default new IsInTheFilters()
