var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.traineesTableHeaders,"items":_vm.needTrainingsAndBookings,"items-per-page":5,"loading":_vm.isLoadingEmailSessionDocuments},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),(!_vm.readonly)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.associateNeedTraining}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_trainee'))+" ")]):_vm._e(),(_vm.trainingSession.status === _vm.IN_PROGRESS && !_vm.readonly)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.canSendDocumentToAllTrainees('convocation')),expression:"canSendDocumentToAllTrainees('convocation')"}],staticClass:"float-right ml-5",attrs:{"color":"secondary","disabled":_vm.isLoadingEmailSessionDocuments},on:{"click":function($event){_vm.sendDocumentToAllTrainees( 'convocation', ("" + (_vm.$t('send_session_document.send_all_convocations'))), ("" + (_vm.$t('send_session_document.title_convocation'))))}}},[_vm._v(" "+_vm._s(_vm.$t('send_session_document.send_all_convocations'))+" ")]):_vm._e(),((_vm.trainingSession.status === _vm.BILLED || _vm.trainingSession.status === _vm.DONE) && !_vm.readonly)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.canSendDocumentToAllTrainees('attestation')),expression:"canSendDocumentToAllTrainees('attestation')"}],staticClass:"float-right ml-5",attrs:{"color":"secondary","disabled":_vm.isLoadingEmailSessionDocuments},on:{"click":function($event){_vm.sendDocumentToAllTrainees( 'attestation', ("" + (_vm.$t('send_session_document.send_all_attestations'))), ("" + (_vm.$t('send_session_document.title_attestation'))))}}},[_vm._v(" "+_vm._s(_vm.$t('send_session_document.send_all_attestations'))+" ")]):_vm._e()],1)]},proxy:true},{key:"item.changeStatus",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('div',{staticClass:"text-center ma-2 rounded"},[(_vm.canToggleNeedTrainingStatus(item))?_c('v-btn-toggle',{on:{"change":function($event){return _vm.changeStatusNeedTraining(item, item.status.code)}},model:{value:(item.status.code),callback:function ($$v) {_vm.$set(item.status, "code", $$v)},expression:"item.status.code"}},_vm._l((_vm.itemsNeedTrainingStatus),function(status){return _c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.isShownNeedTrainingStatus(
                item.status.code,
                status.code
              )
            ),expression:"\n              isShownNeedTrainingStatus(\n                item.status.code,\n                status.code\n              )\n            "}],key:status.code,attrs:{"value":status.code,"loading":_vm.isLoadingNeedTrainingStatus(
                item,
                item.status.code,
                status.code
              ),"disabled":_vm.isDisabledNeedTrainingStatus(
                item,
                item.status.code
              )}},[_vm._v(" "+_vm._s(status.text)+" ")])}),1):_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getColorFromNeedTrainingStatusCode(item.status.code)}},[_vm._v(" "+_vm._s(item.status.status)+" ")])],1):(item.isBooking)?_c('div',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.bookingPending'))+" ")])],1):_vm._e()]}},{key:"item.sendConvocation",fn:function(ref){
              var item = ref.item;
return [(item.trainee && item.trainee.email && item.company)?[(_vm.isShownSendDocumentButton(item, 'convocation'))?_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isLoadingEmailSessionDocuments},on:{"click":function($event){_vm.openDialogDocument(item, 'convocation', ("" + (_vm.$t('send_session_document.title_convocation'))))}}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.headers.trainee.sendConvocation'))+" ")])],1):_c('p',{staticClass:"mb-0",attrs:{"set":_vm.sendAt = _vm.getEmailSessionDocument(item, 'convocation').sendAt}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.send_at'))+" "),(_vm.sendAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateHour")(_vm.sendAt))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateHour")(_vm.formatISO(new Date())))+" ")])])]:_vm._e()]}},{key:"item.sendBackConvocation",fn:function(ref){
              var item = ref.item;
return [(item.trainee && item.trainee.email && item.company && _vm.isShownColumnSendBackDocument(item, 'convocation'))?[(_vm.isShownSendBackDocumentButton(item, 'convocation'))?_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isLoadingEmailSessionDocuments},on:{"click":function($event){_vm.openDialogDocument(item, 'convocation', ("" + (_vm.$t('send_session_document.title_convocation'))), ("" + (_vm.$t('send_session_document.reminder'))))}}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.headers.trainee.sendBackConvocation'))+" ")])],1):_c('p',{staticClass:"mb-0",attrs:{"set":_vm.sendAt = _vm.getEmailSessionDocument(item, 'convocation', false).sendAt}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.send_at'))+" "),(_vm.sendAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateHour")(_vm.sendAt))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateHour")(_vm.formatISO(new Date())))+" ")])])]:_vm._e()]}},{key:"item.sendAttestation",fn:function(ref){
              var item = ref.item;
return [(item.trainee && item.trainee.email)?[(_vm.isShownSendDocumentButton(item, 'attestation'))?_c('div',{staticClass:"text-center ma-2 rounded"},[(item.status.code === 'forme')?_c('v-btn',{attrs:{"disabled":_vm.isLoadingEmailSessionDocuments,"color":"primary"},on:{"click":function($event){_vm.openDialogDocument(item, 'attestation', ("" + (_vm.$t('send_session_document.title_attestation'))))}}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.headers.trainee.sendAttestation'))+" ")]):_vm._e()],1):_c('p',{staticClass:"mb-0",attrs:{"set":_vm.sendAt = _vm.getEmailSessionDocument(item, 'attestation').sendAt}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.send_at'))+" "),(_vm.sendAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateHour")(_vm.sendAt))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateHour")(_vm.formatISO(new Date())))+" ")])])]:_vm._e()]}},{key:"item.id",fn:function(ref){
              var item = ref.item;
return [(item.trainee)?_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToTraineeForm(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.access'))+" ")])],1):(item.id && item.isBooking)?_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToBookingForm(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('training_session.list.access'))+" ")])],1):_vm._e()]}},{key:"item.delete",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","size":"36","color":"primary"}},[_c('v-icon',{on:{"click":function($event){return _vm.removeNeedTraining(item)}}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)}),_c('SendSessionDocumentDialog',{attrs:{"title":_vm.titleSessionDocumentDialog,"dialog":_vm.dialogSessionDocumentNeedTraining,"logistic-session":_vm.logisticSession,"need-training":_vm.needTrainingToSendDocument,"training-session":_vm.trainingSession,"type-document":_vm.typeDocument,"text-pre-object":_vm.textPreObject},on:{"close":function($event){_vm.dialogSessionDocumentNeedTraining=false;},"reload":_vm.reloadEmailSessionDocuments}}),_c('SendSessionDocumentToAllTraineesDialog',{attrs:{"title":_vm.titleSessionDocumentToAllTrainees,"title-document-to-send":_vm.titleSessionDocumentDialog,"dialog":_vm.dialogSendSessionDocumentToAllTrainees,"type-document":_vm.typeDocument,"logistic-session":_vm.logisticSession,"training-session":_vm.trainingSession,"email-session-documents":_vm.emailSessionDocuments},on:{"close":function($event){_vm.dialogSendSessionDocumentToAllTrainees=false},"reload":_vm.reloadEmailSessionDocuments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }