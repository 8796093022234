
































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapMutations, mapState } from 'vuex'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { NeedTrainingStatus } from '@/api/interfaces/needtrainingstatus'
import { EmailSessionDocument } from '@/api/interfaces/emailsessiondocument'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { RawLocation } from 'vue-router'
import SendSessionDocumentDialog from '@/components/molecules/dialog/sendSessionDocument.vue'
import SendSessionDocumentToAllTraineesDialog
  from '@/components/molecules/dialog/sendSessionDocumentToAllTrainees.vue'
import { formatISO } from 'date-fns'
import { Booking } from '@/api/interfaces/booking'
import { BookingStatus } from '@/api/enums/bookingStatus'
import { TrainingSessionStatus } from '@/api/enums/trainingSessionStatus'
import { driverProgram } from '@/config-constantes'
import { TrainingType } from '@/api/interfaces/trainingtype'

export const IN_PROGRESS = TrainingSessionStatus.STATUS_IN_PROGRESS
export const OPTIONAL = TrainingSessionStatus.STATUS_OPTIONAL
export const DONE = TrainingSessionStatus.STATUS_DONE
export const BILLED = TrainingSessionStatus.STATUS_BILLED

export default defineComponent({
  name: 'needTrainingsTableList',
  components: {
    SendSessionDocumentToAllTraineesDialog,
    SendSessionDocumentDialog,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    logisticSession: {
      type: Object,
      required: true,
    },
    idTrainingSession: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value: {
      async handler () {
        this.trainingSession = this.value
        await this.loadEmailSessionDocuments()
      },
      deep: true,
    },
  },
  async created () {
    await this.loadEmailSessionDocuments()
  },
  data () {
    return {
      IN_PROGRESS,
      DONE,
      BILLED,
      itemsNeedTrainingStatus: [
        {
          text: 'Formé',
          code: 'forme',
        },
        {
          text: 'Absent',
          code: 'absent',
        },
      ],
      trainingSession: this.value,
      isLoading: false,
      vBtnClicked: null as NeedTraining | null,
      dialogSessionDocumentNeedTraining: false,
      typeDocument: '',
      titleSessionDocumentDialog: '',
      textPreObject: '',
      titleSessionDocumentToAllTrainees: '',
      dialogSendSessionDocumentToAllTrainees: false,
      needTrainingToSendDocument: {},
      emailSessionDocuments: [] as Array<EmailSessionDocument>,
      isLoadingEmailSessionDocuments: false,
    }
  },
  methods: {
    formatISO,
    ...mapMutations('navigation', {
      forceCancelSaveNextRoute: 'forceCancelSaveNextRoute',
    }),
    ...mapMutations('needTrainingForm', {
      setNeedTraining: 'setNeedTraining',
    }),
    ...mapActions('needTrainingForm', {
      saveNeedTraining: 'save',
    }),
    ...mapActions('logisticSessionForm', {
      saveLogisticSession: 'save',
    }),
    ...mapActions('emailSessionDocument', {
      loadEmailSessionDocumentsByTrainingSession: 'loadByTrainingSession',
    }),
    async changeStatusNeedTraining (
      needTraining: NeedTraining,
      codeNeedTrainingStatus: string,
    ) {
      this.vBtnClicked = needTraining
      this.isLoading = true
      const needTrainingStatus = (this.needTrainingStatusList as Array<
        NeedTrainingStatus
        >).filter(status => status.code === codeNeedTrainingStatus)
      needTraining.status['@id'] = needTrainingStatus[0]['@id']
      this.setNeedTraining(needTraining)
      await this.saveNeedTraining()
      this.isLoading = false
    },
    isLoadingNeedTrainingStatus (
      needTraining: NeedTraining,
      codeNeedTrainingStatus: string,
      btnStatusCode: string,
    ) {
      return (
        this.isLoading &&
        this.vBtnClicked === needTraining &&
        codeNeedTrainingStatus === btnStatusCode
      )
    },
    isDisabledNeedTrainingStatus (
      needTraining: NeedTraining,
      codeNeedTrainingStatus: string,
    ) {
      const itemFound = (this.itemsNeedTrainingStatus as Array<any>).filter(
        status => status.code === codeNeedTrainingStatus,
      )
      return (
        (this.isLoading && this.vBtnClicked === needTraining) ||
        itemFound.length > 0
      )
    },
    isShownNeedTrainingStatus (
      codeNeedTrainingStatus: string,
      btnStatusCode: string,
    ) {
      const itemFound = (this.itemsNeedTrainingStatus as Array<any>).filter(
        status => status.code === codeNeedTrainingStatus,
      )
      return (
        itemFound.length === 0 ||
        (itemFound.length > 0 && codeNeedTrainingStatus === btnStatusCode)
      )
    },
    isShownSendDocumentButton (needTraining: NeedTraining, typeDocument: string): boolean {
      return this.getEmailSessionDocument(needTraining, typeDocument) === null
    },
    isShownSendBackDocumentButton (needTraining: NeedTraining, typeDocument: string): boolean {
      return this.getEmailSessionDocuments(needTraining, typeDocument).length === 1
    },
    isShownColumnSendBackDocument (needTraining: NeedTraining, typeDocument: string): boolean {
      return this.getEmailSessionDocuments(needTraining, typeDocument).length > 0
    },
    getEmailSessionDocuments (needTraining: NeedTraining, typeDocument: string): Array<EmailSessionDocument> {
      return this.emailSessionDocuments.filter(function (item: EmailSessionDocument) {
        return item.needTraining === needTraining['@id'] && item.typeDocument === typeDocument
      })
    },
    getEmailSessionDocument (needTraining: NeedTraining, typeDocument: string, getFirst = true) : EmailSessionDocument | null {
      if (this.emailSessionDocuments.length > 0) {
        const emailsSessionDocuments = this.getEmailSessionDocuments(needTraining, typeDocument)
        emailsSessionDocuments.sort((a: EmailSessionDocument, b: EmailSessionDocument) => {
          if (a.sendAt === null) {
            return 1
          }

          if (b.sendAt === null) {
            return -1
          }
          return new Date(a.sendAt as string).getTime() - new Date(b.sendAt as string).getTime()
        })

        if (getFirst) {
          return emailsSessionDocuments[0] ?? null
        } else {
          return emailsSessionDocuments.length > 0 ? emailsSessionDocuments[emailsSessionDocuments.length - 1] : null
        }
      }

      return null
    },
    canSendDocumentToAllTrainees (typeDocument: string): boolean {
      if (this.emailSessionDocuments === []) {
        return false
      }
      const needTrainingsWhoReceivedAnEmail: Array<any> = this.emailSessionDocuments.map(function (x) {
        if (x.typeDocument === typeDocument) {
          return x.needTraining
        }
      })
      const needTrainings: Array<any> = (this.trainingSession as TrainingSession).needTrainings
      const needTrainingWhoDidNotReceiveAnEmail = needTrainings
        .filter((needTraining: NeedTraining) => {
          if (needTraining.trainee.email === null || needTraining.company === null) {
            return false
          }
          return !needTrainingsWhoReceivedAnEmail.includes(needTraining['@id'])
        })

      return needTrainingWhoDidNotReceiveAnEmail.length > 0
    },
    sendDocumentToAllTrainees (typeDocument: string, titleDialogSendAll: string, titleSendDocument: string) {
      this.typeDocument = typeDocument
      this.titleSessionDocumentToAllTrainees = titleDialogSendAll
      this.titleSessionDocumentDialog = titleSendDocument
      this.dialogSendSessionDocumentToAllTrainees = true
    },
    openDialogDocument (needTraining: NeedTraining, typeDocument: string, title: string, textPreObject = '') {
      this.titleSessionDocumentDialog = title
      this.needTrainingToSendDocument = needTraining
      this.typeDocument = typeDocument
      this.textPreObject = textPreObject
      this.dialogSessionDocumentNeedTraining = true
    },
    async reloadEmailSessionDocuments () {
      await this.loadEmailSessionDocuments()
    },
    associateNeedTraining () {
      const route: RawLocation = {
        name: 'Trainee List Associate TrainingSession',
        params: {
          idLogisticSession: this.logisticSession.id,
          idTrainingSession: this.idTrainingSession,
        },
      }
      this.forceCancelSaveNextRoute(route)
      this.$router.push(route)
    },
    removeNeedTraining (needTraining: NeedTraining) {
      ((this.trainingSession as TrainingSession).needTrainings as Array<NeedTraining>).splice(
        ((this.trainingSession as TrainingSession).needTrainings as Array<NeedTraining>).indexOf(needTraining),
        1,
      )
    },
    canToggleNeedTrainingStatus (needTraining: NeedTraining): boolean {
      const status = ['planifie', 'absent', 'forme']
      return status.includes(needTraining.status.code)
    },
    getColorFromNeedTrainingStatusCode (code: string): string {
      switch (code) {
        case 'forme': return 'success'
        case 'absent': return 'error'
        default: return 'primary'
      }
    },
    goToTraineeForm (id: number) {
      this.$router.push({
        name: 'Trainee Edit',
        params: { idNeedTraining: id.toString() },
      })
    },
    goToBookingForm (id: number) {
      this.$router.push({
        name: 'Booking',
        params: { idBooking: id.toString() },
      })
    },
    async loadEmailSessionDocuments () {
      try {
        this.isLoadingEmailSessionDocuments = true
        this.emailSessionDocuments = await this.loadEmailSessionDocumentsByTrainingSession(this.trainingSession.id)
      } finally {
        this.isLoadingEmailSessionDocuments = false
      }
    },
  },
  computed: {
    ...mapState('status', {
      needTrainingStatusList: 'list',
    }),
    traineesTableHeaders (): Array<any> {
      const headerTrainees = [
        {
          text: this.$t('training_session.list.headers.actions'),
          align: 'center',
          value: 'id',
        },
        {
          text: this.$t('training_session.list.headers.trainee.lastName'),
          align: 'center',
          value: 'trainee.lastName',
        },
        {
          text: this.$t('training_session.list.headers.trainee.firstName'),
          align: 'center',
          value: 'trainee.firstName',
        },
        {
          text: this.$t('training_session.list.headers.trainee.company'),
          align: 'center',
          value: 'company.name',
        },
      ]

      const trainingSessionStatus = (this.trainingSession as TrainingSession).status as TrainingSessionStatus
      const trainingType = (this.trainingSession as TrainingSession).trainingType as TrainingType

      if (trainingType.code === driverProgram) {
        headerTrainees.push(
          {
            text: this.$t('training_session.list.headers.trainee.origin_driver_program'),
            align: 'center',
            value: 'originDriverProgram',
          },
        )
      }

      if (!this.readonly && [IN_PROGRESS, OPTIONAL].includes(trainingSessionStatus)) {
        headerTrainees.push(
          {
            text: this.$t('training_session.list.headers.delete'),
            align: 'center',
            value: 'delete',
          },
        )
      }

      if (trainingSessionStatus === IN_PROGRESS && !this.readonly) {
        headerTrainees.push(
          {
            text: this.$t('training_session.list.headers.trainee.sendConvocation'),
            align: 'center',
            value: 'sendConvocation',
          },
        )

        headerTrainees.push(
          {
            text: this.$t('training_session.list.headers.trainee.sendBackConvocation'),
            align: 'center',
            value: 'sendBackConvocation',
          },
        )
      }

      if (trainingSessionStatus === DONE || trainingSessionStatus === BILLED) {
        headerTrainees.push(
          {
            text: this.$t('training_session.list.headers.trainee.status'),
            align: 'center',
            value: 'changeStatus',
          },
        )

        if (!this.readonly) {
          headerTrainees.push(
            {
              text: this.$t('training_session.list.headers.trainee.sendAttestation'),
              align: 'center',
              value: 'sendAttestation',
            },
          )
        }
      }

      return headerTrainees
    },
    needTrainingsAndBookings (): Array<NeedTraining> {
      const needTrainings = (this.trainingSession as TrainingSession).needTrainings as Array<NeedTraining>
      const bookings = (this.trainingSession as TrainingSession).bookings ?? [] as Array<Booking>

      return needTrainings.concat(
        bookings.filter(
          (booking: Booking) => {
            return booking.bookingStatus === BookingStatus.PENDING &&
              (
                !needTrainings.some((needTraining: NeedTraining) => needTraining.trainee.id === booking.needTraining?.trainee?.id)
              )
          })
          .map((booking: Booking) => {
            return {
              id: booking.id,
              'trainee.id': booking?.needTraining?.trainee?.id,
              'trainee.firstName': booking.firstName,
              'trainee.lastName': booking.lastName,
              'company.name': booking.company,
              isBooking: true,
            }
          }))
    },
  },
})
