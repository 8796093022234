
























import { defineComponent } from '@vue/composition-api'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { LogisticSession } from '@/api/interfaces/logisticSession'

export default defineComponent({
  name: 'needTrainingsVehiclesTableList',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      logisticSession: this.value,
      traineesTableHeaders: [
        {
          text: this.$t('training_session.list.headers.trainee.lastName'),
          align: 'center',
          value: 'trainee.lastName',
        },
        {
          text: this.$t('training_session.list.headers.trainee.firstName'),
          align: 'center',
          value: 'trainee.firstName',
        },
        {
          text: this.$t('training_session.list.headers.trainee.phoneNumber'),
          align: 'center',
          value: 'trainee.phoneNumber',
        },
        {
          text: this.$t('training_session.list.headers.trainee.carBrand'),
          align: 'center',
          value: 'carBrand.brand',
        },
        {
          text: this.$t('training_session.list.headers.trainee.carModel'),
          align: 'center',
          value: 'carModel.model',
        },
      ],
    }
  },
  computed: {
    needTrainings (): Array<NeedTraining> {
      return (this.logisticSession as LogisticSession).trainingSessions?.map((trainingSession: TrainingSession) => {
        return trainingSession.needTrainings as Array<NeedTraining>
      }).flat() ?? [] as Array<NeedTraining>
    },
  },
})
