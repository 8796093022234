/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LogisticSession } from '@/api/interfaces/logisticSession'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'
import { isSameDay } from 'date-fns'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { CalendarDay, CardPosition } from '@/views/dashboard/planning/types/Calendar'
import { LogisticSessionDTO } from '@/composables/planning/logisticSessionDTO'

interface CalendarListState {
  calendarDays: CalendarDay[];
  totalCalendarDays: number;
  loading: boolean;
  logisticSessions: LogisticSession[];
  totalLogisticSessions: number;
}

@Module({ name: 'CalendarList', namespaced: true })
class CalendarListModule extends VuexModule implements CalendarListState {
  public calendarDays: CalendarDay[] = [];
  public totalCalendarDays = 0;
  public logisticSessions: LogisticSession[] = [];
  public totalLogisticSessions = 0;
  public loading = false;

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Mutation
  public setCalendarDays (list: Array<CalendarDay>): void {
    this.calendarDays = list
  }

  @Mutation
  public addCalendarDays (item: CalendarDay): void {
    this.calendarDays = [...this.calendarDays, item]
  }

  @Mutation
  public setTotalCalendarDays (total: number): void {
    this.totalCalendarDays = total
  }

  @Mutation
  public setLogisticSession (list: Array<LogisticSession>): void {
    this.logisticSessions = list
  }

  @Mutation
  public setTotalLogisticSession (total: number): void {
    this.totalLogisticSessions = total
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<void> {
    this.context.commit('setLoading')
    await Promise.all([
      this.context.dispatch('loadCalendarDays', queryParams),
      this.context.dispatch('loadLogisticSession', queryParams),
    ])
    this.context.commit('setLoading')
  }

  @Action({ rawError: true })
  public async postCalendarDay (calendarDay: CalendarDay): Promise<string> {
    await http.post('api/calendar_days', calendarDay).then((response) => {
      this.context.commit('addCalendarDays',
        response.data)
    })
    return 'done'
  }

  @Action({ rawError: true })
  public async putCalendarDay (data: { id: number, calendarDay: CalendarDay }): Promise<string> {
    await http.put(`api/calendar_days/${data.id}`, data.calendarDay)
    return 'done'
  }

  @Action({ rawError: true })
  public async loadCalendarDays (
    queryParams: QueryParams,
  ): Promise<Array<CalendarDay>> {
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`api/calendar_days${option.replaceAll('startAt', 'date')}`)
    this.context.commit('setCalendarDays', response.data['hydra:member'])
    this.context.commit(
      'setTotalCalendarDays',
      response.data['hydra:totalItems'],
    )
    return response.data['hydra:member'] as Array<CalendarDay>
  }

  @Action({ rawError: true })
  public async loadLogisticSession (
    queryParams: QueryParams,
  ): Promise<Array<LogisticSession>> {
    queryParams.pagination = false
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`api/logistic_sessions${option}`)
    this.context.commit('setLogisticSession', response.data['hydra:member'])
    this.context.commit(
      'setTotalLogisticSession',
      response.data['hydra:totalItems'],
    )
    return response.data['hydra:member'] as Array<LogisticSession>
  }

  @Action({ rawError: true })
  public reset (): void {
    this.context.commit('setList', [])
    this.context.commit('setTotalCalendarDays', 0)
  }

  get allPostions (): CardPosition[] {
    return this.calendarDays.reduce((acc, day) => {
      return acc.concat(day.positions)
    }, [] as CardPosition[])
  }

  get listDTO () {
    const logisticSessions = this.logisticSessions.sort((a, b) => {
      if (a.trainingSessions.every((session) => session.status === 'canceled')) {
        return 1
      }
      if (b.trainingSessions.every((session) => session.status === 'canceled')) {
        return -1
      }
      return 0
    })
    const listDTO = logisticSessions.reduce((acc, logisticSession) => {
      const dto = new LogisticSessionDTO(logisticSession)
      const sessionPosition = (this.allPostions).find((position) => logisticSession.id === position.i)
      if (sessionPosition) {
        dto.setPositionY = sessionPosition.y
      } else {
        // if items on the day get
        const positionDay = acc.filter((day) => isSameDay(new Date(day.logisticSession.startAt as string), new Date(dto.logisticSession.startAt as string)))
        if (positionDay.length > 1) {
          const lowestSession = positionDay.reduce((max, session) => session.y && max.y && max.y > session.y ? max : session)
          dto.y = lowestSession && lowestSession.y ? lowestSession.y + lowestSession.h : 0
        } else if (positionDay.length === 1) {
          dto.y = positionDay[0].y ? positionDay[0].y + positionDay[0].h : 0
        } else {
          dto.y = 0
        }
      }
      return [...acc, dto]
    }, [] as LogisticSessionDTO[])
    return listDTO.sort((a, b) => Number(b.positionned) - Number(a.positionned))
  }

  @Action({ rawError: true })
  public async merge (data: { idMergeInto: number, idDelete: number }): Promise<LogisticSession> {
    const response = await http.put(`/api/logistic_sessions/${data.idMergeInto}/merge`, {
      idLogisticSessionToDelete: data.idDelete,
    })
    const elementPos = (this.context.state as CalendarListState).logisticSessions.map(function (x) { return x.id }).indexOf(data.idMergeInto)
    const logisticSessionsFiltered = [...(this.context.state as CalendarListState).logisticSessions.filter(session => session.id !== data.idDelete && session.id !== data.idMergeInto)]
    logisticSessionsFiltered[elementPos] = response.data
    this.context.commit('setLogisticSession', [...logisticSessionsFiltered])
    return response.data as LogisticSession
  }
}
export default CalendarListModule
