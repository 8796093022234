import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios'
import { formatDate, formatDateHour, formatHour } from './plugins/format-date'
import i18n from './i18n'
import './sass/main.scss'
import {
  trainingLocationTypes,
  trainingLocationTypeCarDealer,
  trainingLocationTypeSubRegion,
  trainingLocationRencontresCode,
  trainingSessionStatuses,
  carDealerRegions,
  needTrainingStatusesCodesToPlanifie,
  roles,
  codeTeachingResourcesConvention,
  brands,
  professionGestionnaire,
  relationClients,
  accountTypes,
  groupSuperAdmin,
  groupTrainingOfficerDriverProgram,
  groupTrainingOfficerOthers,
  trainingTypePictograms,
  driverProgram,
  itemsStatusLogisticSession,
  itemsStatusLogisticSessionCircuit,
  itemsStatusLogisticSessionSupplierRelationship,
  originsDriverProgram,
  emailNotificationTypeDocuments,
  emailNotificationTypes,
  emailNotificationBrands,
  typeEvaluations,
  needTrainingStatusFormeAndAbsent,
  trainingCompanyTypes,
} from '@/config-constantes'
import { vMaska } from 'maska/dist/vue'

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$appConfig = {
  trainingLocationTypes,
  trainingLocationTypeCarDealer,
  trainingLocationTypeSubRegion,
  trainingLocationRencontresCode,
  trainingSessionStatuses,
  carDealerRegions,
  needTrainingStatusesCodesToPlanifie,
  roles,
  codeTeachingResourcesConvention,
  brands,
  professionGestionnaire,
  relationClients,
  accountTypes,
  trainingCompanyTypes,
  groupSuperAdmin,
  groupTrainingOfficerDriverProgram,
  groupTrainingOfficerOthers,
  trainingTypePictograms,
  driverProgram,
  itemsStatusLogisticSession,
  itemsStatusLogisticSessionCircuit,
  itemsStatusLogisticSessionSupplierRelationship,
  originsDriverProgram,
  emailNotificationTypeDocuments,
  emailNotificationTypes,
  emailNotificationBrands,
  typeEvaluations,
  needTrainingStatusFormeAndAbsent,
}

Vue.use(VueCompositionApi)

Vue.filter('formatDate', formatDate)
Vue.filter('formatDateHour', formatDateHour)
Vue.filter('formatHour', formatHour)

Vue.directive('uppercase', {
  update (el) {
    const sourceValue = el.getElementsByTagName('input')[0].value
    const newValue = sourceValue.toUpperCase()

    if (sourceValue !== newValue) {
      el.getElementsByTagName('input')[0].value = newValue
      el = el.getElementsByTagName('input')[0]
      el.dispatchEvent(new Event('input', { bubbles: true }))
    }
  },
})

Vue.directive('maska', vMaska)

export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
