var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[(_vm.canAddLogisticSessionAndTrainingSession())?_c('v-btn',{staticClass:"mb-0",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogLogisticSession = true}}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_training_session'))+" ")]):_vm._e()],1)],2),_c('v-container',{attrs:{"id":"trainer-list","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-clipboard-text"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_c('div',[_vm._v(_vm._s(_vm.$t('training_session.list.title')))])])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.trainingSessionList,"options":_vm.paginationOptions,"hide-default-footer":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"header.trainingType.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"multiple":"","translation":_vm.$t('training_session.list.filter.training_type'),"value":_vm.filters.trainingTypeFilter,"items":_vm.trainingTypesList,"item-text":function (item) { return item.name; }},on:{"filter-list":function($event){_vm.filters = Object.assign({}, _vm.filters, {trainingTypeFilter: $event})}}})],1)]}},{key:"header.trainingLocation.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('training_session.list.filter.training_location'),"value":_vm.filters.trainingLocationFilter},on:{"filter-list":function($event){_vm.filters = Object.assign({}, _vm.filters, {trainingLocationFilter: $event})}}})],1)]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"multiple":"","translation":_vm.$t('training_session.list.filter.status'),"value":_vm.filters.trainingStatus,"items":_vm.$appConfig.trainingSessionStatuses,"item-value":function (item) { return item.value; },"item-text":function (item) { return _vm.$t('training_session.form.status.' + item.value); }},on:{"filter-list":function($event){_vm.filters = Object.assign({}, _vm.filters, {trainingStatus: $event})}}})],1)]}},{key:"header.startAt",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('DateFieldFilter',{attrs:{"label":_vm.$t('form.date_start'),"value":_vm.filters.date},on:{"day-with-no-hours":function($event){_vm.filters = Object.assign({}, _vm.filters, {date: $event})}}})],1)]}},{key:"item.startAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateHour")(item.startAt))+" "),(item.endAt !== null)?_c('span',[_vm._v(" - "+_vm._s(_vm._f("formatHour")(item.endAt))+" ")]):_vm._e()]}},{key:"item.goalNumberTrainees",fn:function(ref){
var item = ref.item;
return [(item.goalNumberTrainees)?[_vm._v(" "+_vm._s(_vm.useTrainingSession().getNumberTraineesInSession(item) + "/" + item.goalNumberTrainees)+" ")]:_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('training_session.form.status.' + item.status))+" ")]}},{key:"header.trainingCompanyType",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"translation":_vm.$t('training_session.list.filter.training_company_type'),"value":_vm.filters.trainingCompanyType,"items":_vm.$appConfig.trainingCompanyTypes,"item-text":function (item) { return _vm.$t('training_company_type.' + item.value); },"item-value":function (item) { return item.value; }},on:{"filter-list":function($event){_vm.filters = Object.assign({}, _vm.filters, {trainingCompanyType: $event})}}})],1)]}},{key:"item.trainingCompanyType",fn:function(ref){
var item = ref.item;
return [(item.trainingCompanyType)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('training_company_type.' + item.trainingCompanyType))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditLogisticSession())?_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToTrainingSessionForm(item)}}},[_vm._v(" mdi-pencil ")])],1)],1):_vm._e()]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)],1),(_vm.dialogLogisticSession)?_c('CreateLogisticSession',{on:{"close":function($event){_vm.dialogLogisticSession = false}},model:{value:(_vm.dialogLogisticSession),callback:function ($$v) {_vm.dialogLogisticSession=$$v},expression:"dialogLogisticSession"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }