



















































































































































































































































































































import frLocale from 'date-fns/locale/fr'
import { defineComponent } from '@vue/composition-api'
import { mapState, mapActions, mapGetters } from 'vuex'
import {
  addDays,
  getWeek,
  format,
  formatISO,
  parseJSON,
  parseISO,
} from 'date-fns'
import type { Area } from '@/api/interfaces/area'
import type { LogisticArea } from '@/api/interfaces/logisticArea'
import CreateEventDay from '@/components/molecules/dialog/createEventDay.vue'
import { userConnectedHasRole } from '@/composables/UserGrant'
import SelectSearchTrainingType from '@/components/molecules/select-search/trainingType.vue'
import { TrainingSessionStatus } from '@/api/enums/trainingSessionStatus'

export default defineComponent({
  props: {
    value: {
      required: true,
      type: Object,
      default: {},
    },
    logisticArea: {
      required: false,
      type: String,
      default: '',
    },
    currentDay: {
      required: true,
      type: String,
      default: formatISO(new Date()),
    },
  },
  components: {
    SelectSearchTrainingType,
    SelectTrainingSessionStatus: () =>
          import('@/components/molecules/select/trainingSessionStatus.vue'),
    SelectTrainingLocation: () =>
          import('@/components/molecules/select-search/location.vue'),
    SelectCompanies: () =>
          import('@/components/molecules/select-search/company.vue'),
    SelectTrainers: () => import('@/components/molecules/select-search/trainers.vue'),
    SelectArea: () => import('@/components/molecules/select/area.vue'),
    CreateEventDay,
  },
  name: 'FiltersPlanning',
  data () {
    return {
      preparationOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false },
      ],
      datePickerDialog: false,
      canCreateEventDay: userConnectedHasRole('ROLE_WRITE_EVENT_DAY'),
    }
  },
  computed: {
    ...mapGetters('trainingType', {
      trainingTypesList: 'getListActiveItems',
    }),
    ...mapGetters('user', {
      userConnectedIsTrainer: 'isTrainer',
      areasAuthorized: 'areasAuthorized',
      getLogisticAreaInFilters: 'getLogisticAreaInFilters',
    }),
    ...mapState('logisticArea', {
      logisticAreaList: 'list',
    }),
    ...mapState('user', {
      userConnected: 'userConnected',
    }),
    ...mapState('area', {
      areasList: 'list',
    }),
    ...mapState('trainerList', {
      trainerList: 'list',
    }),
    ...mapState('trainingLocationList', {
      trainingLocationList: 'list',
    }),
    statusItems (): {value: string, icon: string}[] {
      return (this as any).$appConfig.trainingSessionStatuses.filter((status: {value: string}) => this.value.displayCanceled || status.value !== TrainingSessionStatus.STATUS_CANCELED)
    },
    areasListFiltered (): Area[] {
      const logisticArea: LogisticArea | undefined = this.logisticAreaList.find((x: Area) => x['@id'] === this.logisticArea)
      if (logisticArea?.areas.length === 0 || logisticArea?.isSpecificTrappesSpe) {
        return this.areasList as Area[]
      }
      return (this.areasList as Area[]).filter((area: Area) => logisticArea?.areas.includes(area['@id'] || ''))
    },

    dateIso: {
      get (): string | null {
        return this.value
          ? formatISO(parseJSON(this.currentDay), { representation: 'date' })
          : null
      },
      set (value: string) {
        if (value) {
          this.$emit('changeCurrentDay', formatISO(parseISO(value)))
        }
      },
    },
  },

  created () {
    this.loadTrainer({ pagination: false })
    this.loadTrainingLocation()
    this.loadLogisticArea().then(logisticAreas => {
      if (logisticAreas.length > 0) {
        if (this.userConnectedIsTrainer) {
          const regionManagerAreasByLogisticArea = this.getRegionManagerAreasByLogisticArea(logisticAreas)
          this.selectLogisticAreaWithTheMostManagerRegionAreas(regionManagerAreasByLogisticArea)
          this.addRegionManagerAreasInFilter(regionManagerAreasByLogisticArea)
        } else {
          if (!this.logisticArea) {
            if (this.getLogisticAreaInFilters) {
              this.$emit('changeLogisticArea', this.getLogisticAreaInFilters)
            } else {
              this.selectDefaultLogisticArea(logisticAreas)
            }
          }
        }
      }
    })
  },
  methods: {
    ...mapActions('trainingLocationList', {
      loadTrainingLocation: 'load',
    }),
    ...mapActions('logisticArea', {
      loadLogisticArea: 'load',
    }),
    ...mapActions('trainerList', {
      loadTrainer: 'load',
    }),
    changeLogisticArea (value: string) {
      this.$emit('changeLogisticArea', value)
    },
    addDay (nb: number) {
      this.$emit('changeCurrentDay', formatISO(addDays(new Date(this.currentDay), nb)))
    },
    emitToday () {
      this.$emit('changeCurrentDay', formatISO(new Date()))
    },
    formatWeekNumber (date: string) {
      const dateConverted = new Date(date)
      const string = `${format(dateConverted, 'MMMM yyyy', {
        locale: frLocale,
      })}, S${getWeek(dateConverted)}`
      // Capitalize first letter
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getRegionManagerAreasByLogisticArea (logisticAreas : LogisticArea[]) : [Area[]] {
      const regionManagerAreas = this.userConnected.areas
      const regionManagerAreasByLogisticArea = logisticAreas.map((logisticArea :LogisticArea) => {
        return regionManagerAreas.filter((area: Area) => {
          return area.logisticAreas.includes(logisticArea['@id'] as string)
        })
      })

      // sort with the logistics area that has the most areas
      regionManagerAreasByLogisticArea.sort((a: [], b: []) => {
        return b.length - a.length
      })

      return regionManagerAreasByLogisticArea as [Area[]]
    },
    selectLogisticAreaWithTheMostManagerRegionAreas (regionManagerAreasByLogisticArea : [Area[]]) : void {
      if (regionManagerAreasByLogisticArea) {
        this.$emit('changeLogisticArea', regionManagerAreasByLogisticArea[0][0].logisticAreas[0])
      }
    },
    addRegionManagerAreasInFilter (regionManagerAreasByLogisticArea : [Area[]]): void {
      const selectedAreas = regionManagerAreasByLogisticArea[0].map((area : Area) => area['@id'])
      this.value.area = selectedAreas as string[]
    },
    selectDefaultLogisticArea (logisticAreas: LogisticArea[]) {
      const logisticAreaSelected =
        logisticAreas.find((logisticArea: LogisticArea) => logisticArea.preparer?.id === this.userConnected.userApplication.id) ??
        logisticAreas.find((logisticArea: LogisticArea) => logisticArea.areas.length === 0)
      this.$emit('changeLogisticArea', (logisticAreaSelected as LogisticArea)['@id'])
    },
  },
})
