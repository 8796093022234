




















import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { Trainer } from '@/api/interfaces/trainer'
import { QueryParams } from '@/store/api-plateform-utils'

export default defineComponent({
  name: 'SelectSearchTrainer',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
  },
  props: {
    value: {
      type: [Object, Array],
      required: false,
      default: () => null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('trainerList', {
      listTrainer: 'list',
    }),
    baseRequestTrainers (): QueryParams {
      const request: Record<string, unknown> = {
        pagination: false,
      }
      return request as QueryParams
    },
  },
  async created () {
    this.loadTrainer(this.baseRequestTrainers)
  },
  methods: {
    ...mapActions('trainerList', {
      loadTrainer: 'load',
      saveTrainer: 'save',
    }),
    itemText (item: Trainer) {
      return item.lastName + ' ' + item.firstName
    },
    onInput (items: Array<string>) {
      this.$emit('input', items)
    },
    onChange (value: Trainer|Array<Trainer>) {
      this.$emit('change', value)
    },
  },
})
