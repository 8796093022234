


















































import { defineComponent } from '@vue/composition-api'
import { useDate } from '@/composables/date'

export default defineComponent({
  name: 'AlertTrainerAvailability',
  props: {
    trainersAvailability: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      headersTrainersAvailability: [
        {
          text: this.$t('trainer_availability.trainer'),
          value: 'trainer',
        },
        {
          text: this.$t('trainer_availability.logistic_session'),
          value: 'logisticSessions',
        },
      ],
    }
  },
  methods: {
    useDate,
    setOpenValue (opened: boolean) : void {
      this.$emit('input', opened)
    },
    confirm () : void {
      this.setOpenValue(false)
      this.$emit('confirm')
    },
  },
})
