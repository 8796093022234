





















































































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { TrainingType } from '@/api/interfaces/trainingtype'
export default defineComponent({

  components: {
    DateField: () => import('@/components/base/DateField.vue'),
    SelectCompanies: () =>
    import('@/components/molecules/select-search/company.vue'),
    SelectConcession: () =>
    import('@/components/molecules/select-search/carDealer.vue'),
    SelectCompanyAccountType: () => import('@/components/molecules/select/companyAccountType.vue'),
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('status', {
      statusesList: 'list',
    }),
    ...mapState('trainingType', {
      trainingTypesList: 'list',
    }),
    ...mapState('company', {
      allCompanies: 'list',
    }),
    ...mapState('carDealerList', {
      carDealerList: 'list',
    }),
    ...mapFields('filterList', [
      'filter.trainee.lastName',
      'filter.trainee.phones',
      'filter.trainee.firstName',
      'filter.trainee.email',
      'filter.carNumberPlate',
      'filter.commandNoOneOrNoTwo',
      'filter.businessIntroducer',
      'filter.company',
      'filter.trainingType',
      'filter.status',
      'filter.carDealer',
      'filter.accountType',
      'filter.deliveryDate',
      'filter.trainingSession.startAt',
      'filter.exists.sendToCallCenterAt',
    ]),
    ...mapFields('filterList', {
    }),
    trainingTypesListFiltered (): Array<TrainingType> {
      return (this.trainingTypesList as Array<TrainingType>).filter((trainingType: TrainingType) => trainingType.isActive)
    },
  },
  methods: {
    ...mapActions('import', {
      loadTraineeImport: 'load',
    }),
  },
})
