/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { NeedTraining } from '@/api/interfaces/needtraining'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'
import { FiltersParams } from '@/views/dashboard/trainee/store/filterList'

export interface TraineeListState {
  list: Array<NeedTraining>
  totalItems: number
  loading: boolean
  exporting: boolean
  isLoadingMassDeleting: boolean
}

@Module({ name: 'traineeList', namespaced: true })
class TraineeListModule extends VuexModule implements TraineeListState {
  public list: Array<NeedTraining> = []
  public totalItems = 0
  public loading = false
  public exporting = false
  public isLoadingMassDeleting = false

  @Mutation
  public setList (list: Array<NeedTraining>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public toggleLoading (): void {
    this.loading = !this.loading
  }

  @Mutation
  public toggleExporting (): void {
    this.exporting = !this.exporting
  }

  @Mutation
  public setIsLoadingMassDeleting (isLoadingMassDeleting: boolean): void {
    this.isLoadingMassDeleting = isLoadingMassDeleting
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<NeedTraining>> {
    this.context.commit('toggleLoading')

    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/need_trainings${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('toggleLoading')

    return response.data['hydra:member'] as Array<NeedTraining>
  }

  @Action({ rawError: true })
  public async deleteById (id: number): Promise<Array<NeedTraining>> {
    this.context.commit('toggleLoading')
    const response = await http.delete(`/api/need_trainings/${id}`)
    this.context.commit('setList', this.list.filter(item => item.id !== id))
    this.context.commit('setTotalItems', this.totalItems - 1)
    this.context.commit('toggleLoading')
    return response.data['hydra:member'] as Array<NeedTraining>
  }

  @Action({ rawError: true })
  public async massDelete (filters: FiltersParams): Promise<void> {
    try {
      this.context.commit('setIsLoadingMassDeleting', true)
      await http.post('/api/need_trainings/mass_delete', { filters: filters })
      this.context.commit('toggleLoading')
      this.context.commit('setList', [])
      this.context.commit('setTotalItems', 0)
      this.context.commit('toggleLoading')
    } finally {
      this.context.commit('setIsLoadingMassDeleting', false)
    }
  }

  @Action({ rawError: true })
  public resetList (): void {
    this.context.commit('setList', [])
    this.context.commit('setTotalItems', 0)
  }
}

export default TraineeListModule
