











































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { QueryParams } from '@/store/api-plateform-utils'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import { format, parseJSON } from 'date-fns'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { userConnectedCanAccess, userConnectedHasRole } from '@/composables/UserGrant'
import { useTrainingSession } from '@/composables/trainingSession'

export default defineComponent({
  setup (props, ctx) {
    const canEditLogisticSession = () => userConnectedCanAccess('LogisticSession Edit')
    const canAddLogisticSessionAndTrainingSession = () => userConnectedHasRole('ROLE_WRITE_TRAINING_SESSION')

    const query = {
      ...router.app.$route.query,
    }
    if (!query.sortBy) {
      query.sortBy = 'startAt'
    }
    if (!query.sortDesc) {
      query.sortDesc = 'true'
    }
    const filters = ref({
      trainingTypeFilter: query.trainingTypeFilter || '',
      trainingLocationFilter: query.trainingLocationFilter || '',
      trainingStatus: query.trainingStatus || '',
      trainingCompanyType: query.trainingCompanyType || '',
      date: query.date || '',
    })
    const totalItems = () => {
      return ctx.root.$store.state.trainingSessionList.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      filters,
      canEditLogisticSession,
      canAddLogisticSessionAndTrainingSession,
    }
  },
  name: 'TrainingSessionList',
  components: {
    TopBar,
    ListSelectFilter: () => import('@/components/base/ListSelectFilter.vue'),
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    Pagination,
    DateFieldFilter: () => import('@/components/base/DateFieldFilter.vue'),
    CreateLogisticSession: () => import('@/components/molecules/dialog/createLogisticSession.vue'),
  },
  data () {
    return {
      title: this.$t('training_session.list.title_header'),
      headers: [
        {
          text: this.$t('training_session.list.headers.trainingType'),
          align: 'start',
          value: 'trainingType.name',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.trainingLocation'),
          value: 'trainingLocation.name',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.at'),
          value: 'startAt',
        },
        {
          text: this.$t('training_session.list.headers.goal_number_trainees'),
          value: 'goalNumberTrainees',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.status'),
          value: 'status',
        },
        {
          text: this.$t('training_session.list.headers.training_company_type'),
          value: 'trainingCompanyType',
        },
        {
          text: this.$t('training_session.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      dialogLogisticSession: false,
    }
  },
  computed: {
    ...mapState('trainingSessionList', {
      trainingSessionList: 'list',
      totalItems: 'totalItems',
      loading: 'loading',
    }),
    ...mapGetters('trainingType', {
      trainingTypesList: 'getListActiveItems',
    }),
    ...mapState('trainingLocationList', {
      trainingLocationsList: 'list',
    }),
    ...mapState('filterList', ['filter']),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        trainingType: this.filters.trainingTypeFilter,
        'trainingLocation.name': this.filters.trainingLocationFilter,
        status: this.filters.trainingStatus,
        trainingCompanyType: this.filters.trainingCompanyType,
        date: this.filters.date
          ? format(
            parseJSON(this.filters.date as string) || '',
            'dd-MM-yyyy',
          )
          : '',
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },

  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.loadData()
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  async created () {
    this.load(this.baseRequest)
  },
  methods: {
    useTrainingSession,
    ...mapActions('trainingSessionList', {
      load: 'load',
    }),
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'TrainingSession List', query: query })
    },
    goToTrainingSessionForm (item: TrainingSession) {
      if (item.logisticSessions && item.logisticSessions[0].id) {
        this.$router.push({
          name: 'LogisticSession Edit',
          params: { idLogisticSession: item.logisticSessions[0].id.toString() },
        })
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'LogisticSession Edit' || to.name === 'LogisticSession Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
