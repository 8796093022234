/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Booking } from '@/api/interfaces/booking'
import http from '@/plugins/axios'
import { NeedTraining } from '@/api/interfaces/needtraining'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'

export interface BookingState {
  booking?: Booking
  list?: Array<Booking>
  totalItems?: number
  loading?: boolean
  fromBooking?: boolean
}

function newBooking (): Booking {
  return {
    '@id': '',
    bookingStatus: '',
    carDealer: {},
    comment: '',
    company: '',
    siret: '',
    renter: '',
    deliveryDate: undefined,
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    phone: '',
    plateNumber: '',
    trainingSession: {
      trainingType: {},
      trainingLocation: {},
    },
    carBrand: {},
    carModel: {},
    carEngine: {},
    needTraining: null,
    bookingCode: '',
  }
}

@Module({ name: 'booking', namespaced: true })
class BookingModule extends VuexModule implements BookingState {
  public booking: Booking = newBooking();
  public list: Array<Booking> = []
  public totalItems = 0
  public loading = false
  public fromBooking = false

  @Mutation
  public setList (list: Array<Booking>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Mutation
  public setBooking (booking: Booking): void {
    this.booking = booking
  }

  @Mutation
  public setFromBooking (value: boolean): void {
    this.fromBooking = value
  }

  @Mutation
  public setNeedTraining (value: NeedTraining | null): void {
    this.booking.needTraining = value
  }

  @Mutation
  public setBookingStatus (value: string): void {
    this.booking.bookingStatus = value
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<Booking>> {
    this.context.commit('setLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/bookings${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<Booking>
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Booking> {
    const response = await http.get(`/api/bookings/${id}`)
    const booking = response.data as Booking
    this.context.commit('setBooking', booking)
    return booking
  }

  @Action({ rawError: true })
  public async cancel (id: number): Promise<void> {
    const response = await http.put(`/api/bookings/${id}/cancel`, {})
    this.context.commit('setBookingStatus', response.data.bookingStatus)
  }

  @Action({ rawError: true })
  public async validate (id: number): Promise<void> {
    await http.put(`/api/bookings/${id}/validate`, {})
  }

  @Action({ rawError: true })
  public async associateTrainees (): Promise<void> {
    this.context.commit('setFromBooking', true)
  }

  @Action({ rawError: true })
  public async associateNeedTrainingToBooking (needTraining: NeedTraining) {
    await http.put(`/api/bookings/${this.booking.id}`, {
      needTraining: needTraining['@id'],
    })
    this.context.commit('setNeedTraining', needTraining)
    this.context.commit('setFromBooking', false)
  }
}

export default BookingModule
